import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import CommingSoon from "../../components/commingsoon";

function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Visites virtuelles 3D Matterport"
      />
<div className="mx-auto max-w-screen-xl">
  <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
    <h1>Nos réalisations avec 3D Matterport - Espaces culturels</h1>
    <div className="text-center max-w-xl mx-auto">
    </div>
  </div>
  
  <CommingSoon/>
</div>
    </Layout>
  );
}

export default VisitePage;
